<template>
  <div class="flex items-center text-sm font-bold">
    <skeleton v-if="waiting" class="h-6 w-full" />
    <div v-else class="flex items-center">
      <HomeIcon
        class="h-5 w-5 cursor-pointer hover:text-indigo-600"
        :class="!items.length ? 'text-indigo-500' : null"
        @click="router.push('/')"
      />
      <div v-for="(item, index) in items" :key="item" class="flex items-center">
        <ChevronRightIcon class="mx-2 h-3 w-3" />
        <span
          class="cursor-pointer hover:text-indigo-600"
          :class="index == items.length - 1 ? 'text-indigo-500' : null"
          @click="router.push(item.to)"
          >{{
            item.translatable ? t(`breadcrumb.${item.label}`) : item.label
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon, HomeIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Skeleton from "../loading/Skeleton.vue";
export default {
  components: {
    ChevronRightIcon,
    HomeIcon,
    Skeleton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const items = ref([]);

    const company = computed(() => store.state.company.company);
    const partner = computed(() => store.state.partner.partner);
    const user = computed(() => store.state.user.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.partner.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const setBreadcrumbs = () => {
      items.value = [];
      let label = "";
      let to = "";
      for (const item of route.meta.breadcrumb) {
        let translatable = false;
        if (item == "company") {
          label = company.value.name;
          to += `/${company.value.id}`;
        } else if (item == "partner") {
          label = partner.value.name;
          to += `/${partner.value.id}`;
        } else if (item == "user") {
          label = `${user.value.firstName} ${user.value.lastName}`;
          if (to.includes("companies")) {
            to += "/persons";
          }
          to += `/${user.value.id}`;
        } else {
          label = item;
          if (!to.includes("companies") && item != "persons") {
            to += `/${item}`;
          }
          translatable = true;
        }
        items.value.push({
          label,
          to,
          translatable,
        });
      }
    };

    onMounted(() => setBreadcrumbs());

    watch(
      () => company.value,
      () => {
        setBreadcrumbs();
      }
    );

    watch(
      () => partner.value,
      () => {
        setBreadcrumbs();
      }
    );

    watch(
      () => user.value,
      () => {
        setBreadcrumbs();
      }
    );

    return {
      items,
      router,
      setBreadcrumbs,
      t,
      waiting,
    };
  },
};
</script>
